
/* You can add global styles to this file, and also import other style files */
@import "@angular/material/prebuilt-themes/indigo-pink.css";
@import "~primeng/resources/themes/nova/theme.css";
@import "~primeng/resources/primeng.min.css";

/* Estilos loader */

#loader {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 1051;
  background-color: #333;
  opacity: .7;
  display: none;
  /*margin-left: 100px;*/
}

#loader-fem {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 1051;
  background: rgba(255, 255, 255, 0.4);
  display: none;
  /*margin-left: 100px;*/
}

#loader-usef {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 1051;
  background: rgba(255, 255, 255, 0.4);
  display: none;
  /*margin-left: 100px;*/
}

#loader-fei {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 1051;
  background: rgba(255, 255, 255, 0.4);
  display: none;
  /*margin-left: 100px;*/
}

#loader-ec {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 1051;
  background: rgba(255, 255, 255, 0.4);
  /*display: none;*/
}

.sk-cube-grid {
  position: fixed;
  left: 45%;
  top: 50%;
  z-index: 2;
  width: 60px;
  height: 60px;
  /*margin: -75px 0 0 -75px;*/
}

#loader-fem .sk-cube-grid {
  margin: -75px 0 0 -75px;
}

#loader-fei .sk-cube-grid {
  margin: -75px 0 0 -75px;
}

#loader-usef .sk-cube-grid {
  margin: -75px 0 0 0;
}

#loader-ec .sk-cube-grid {
  margin: -75px 0 0 0;
}


.sk-cube-grid .sk-cube {
  width: 33%;
  height: 33%;
  background-color: #fff;
  float: left;
  -webkit-animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out;
  animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out;
}
.sk-cube-grid .sk-cube1 {
  -webkit-animation-delay: 0.2s;
          animation-delay: 0.2s; }
.sk-cube-grid .sk-cube2 {
  -webkit-animation-delay: 0.3s;
          animation-delay: 0.3s; }
.sk-cube-grid .sk-cube3 {
  -webkit-animation-delay: 0.4s;
          animation-delay: 0.4s; }
.sk-cube-grid .sk-cube4 {
  -webkit-animation-delay: 0.1s;
          animation-delay: 0.1s; }
.sk-cube-grid .sk-cube5 {
  -webkit-animation-delay: 0.2s;
          animation-delay: 0.2s; }
.sk-cube-grid .sk-cube6 {
  -webkit-animation-delay: 0.3s;
          animation-delay: 0.3s; }
.sk-cube-grid .sk-cube7 {
  -webkit-animation-delay: 0s;
          animation-delay: 0s; }
.sk-cube-grid .sk-cube8 {
  -webkit-animation-delay: 0.1s;
          animation-delay: 0.1s; }
.sk-cube-grid .sk-cube9 {
  -webkit-animation-delay: 0.2s;
          animation-delay: 0.2s; }

.sk-cube-grid.ed .sk-cube {
  width: 33.33%;
  height: 33.33%;
  background-color: transparent;
  float: left;
  -webkit-animation: sk-cubeGridScaleDelay 1.9s infinite ease-in-out;
  animation: sk-cubeGridScaleDelay 1.9s infinite ease-in-out;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-color:transparent
}

.sk-cube-grid.ed .sk-cube1 {
  -webkit-animation-delay: 0.2s;
          animation-delay: 0.2s;
         background-image: url('assets/images/logo-ed/logo-ed1.svg');
}
.sk-cube-grid.ed .sk-cube2 {
  -webkit-animation-delay: 0.3s;
          animation-delay: 0.3s;
         background-image: url('assets/images/logo-ed/logo-ed2.svg');
}
.sk-cube-grid.ed .sk-cube3 {
  -webkit-animation-delay: 0.4s;
          animation-delay: 0.4s;
         background-image: url('assets/images/logo-ed/logo-ed3.svg');
}
.sk-cube-grid.ed .sk-cube4 {
  -webkit-animation-delay: 0.1s;
          animation-delay: 0.1s;
         background-image: url('assets/images/logo-ed/logo-ed4.svg');
}
.sk-cube-grid.ed .sk-cube5 {
  -webkit-animation-delay: 0.2s;
          animation-delay: 0.2s;
         background-image: url('assets/images/logo-ed/logo-ed5.svg');
}
.sk-cube-grid.ed .sk-cube6 {
  -webkit-animation-delay: 0.3s;
          animation-delay: 0.3s;
         background-image: url('assets/images/logo-ed/logo-ed6.svg');
}
.sk-cube-grid.ed .sk-cube7 {
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
         background-image: url('assets/images/logo-ed/logo-ed7.svg');
}
.sk-cube-grid.ed .sk-cube8 {
  -webkit-animation-delay: 0.1s;
          animation-delay: 0.1s;
         background-image: url('assets/images/logo-ed/logo-ed8.svg');
}
.sk-cube-grid.ed .sk-cube9 {
  -webkit-animation-delay: 0.2s;
          animation-delay: 0.2s;
         background-image: url('assets/images/logo-ed/logo-ed9.svg');
}

@-webkit-keyframes sk-cubeGridScaleDelay {
  0%, 70%, 100% {
    -webkit-transform: scale3D(1, 1, 1);
            transform: scale3D(1, 1, 1);
  } 35% {
    -webkit-transform: scale3D(0, 0, 1);
            transform: scale3D(0, 0, 1);
  }
}

@keyframes sk-cubeGridScaleDelay {
  0%, 70%, 100% {
    -webkit-transform: scale3D(1, 1, 1);
            transform: scale3D(1, 1, 1);
  } 35% {
    -webkit-transform: scale3D(0, 0, 1);
            transform: scale3D(0, 0, 1);
  }
}

#US_Equestrian .path{
    stroke-dasharray: 1500;
    stroke-dashoffset: 1500;
    fill-opacity: 0;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
    animation-name: DrawLine, FadeStroke, FillIn;
    animation-delay: 0s, 3.5s, 3.5s;
    animation-duration: 3.5s, 1s, 1s;
  }

#fem-loader .st0{
  stroke-dasharray: 1500;
  stroke-dashoffset: 1500;
  fill-opacity: 0;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  animation-name: DrawLine, FadeStroke, FillIn;
  animation-delay: 0s, 3.5s, 3.5s;
  animation-duration: 3.5s, 1s, 1s;
}

#fem-loader .st1{
  stroke-dasharray: 1500;
  stroke-dashoffset: 1500;
  fill-opacity: 0;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  animation-name: DrawLine, FadeStroke, FillIn;
  animation-delay: 0s, 3.5s, 3.5s;
  animation-duration: 3.5s, 1s, 1s;
}



#fem-loader .st3.st4.st5{
  stroke-dasharray: 300;
  stroke-dashoffset: 300;
  fill-opacity: 0;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  animation-name: DrawLine, FadeStroke, FillIn;
  animation-delay: 2s, 3.5s, 3.5s;
  animation-duration: 2s, 1s, 1s;
}



#US_Equestrian .path-text{
  stroke-dasharray: 300;
  stroke-dashoffset: 300;
  fill-opacity: 0;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  animation-name: DrawLine, FadeStroke, FillIn;
  animation-delay: 2s, 3.5s, 3.5s;
  animation-duration: 2s, 1s, 1s;
}

#FEI-svg .path {
  stroke-dasharray: 1500;
  stroke-dashoffset: 1500;
  fill-opacity: 0;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  animation-name: DrawLine, FadeStroke, FillIn;
  animation-delay: 0s, 2s, 2s;
  animation-duration: 3.5s, 1s, 1s;
}

@keyframes DrawLine {
  to { stroke-dashoffset: 0; }
}

@keyframes FadeStroke {
  to { stroke-opacity: 0; }
}

@keyframes FillIn {
  from { fill-opacity: 0; }
  to { fill-opacity: 1; }
}

/* Estilos input fecha PrimeNG */
.ui-fluid .ui-inputtext {
  border-width: 2px;
  box-shadow: none;
  padding: 10px 12px;
  font-size: 14px;
  width: 100%;
  height: 44px;
  background-color: #fff;
  border-color: #eaeaea;
  border-radius: 0;
  -webkit-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
  -o-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
  transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
  line-height: 1.42857143;
  color: #777;
  display: block;
  background-image: none;
}

.ui-inputtext:enabled:hover {
  border-color: #eaeaea;
}

.ui-inputtext:enabled:active {
  border-color: #c0c0c0;
}

/* Ventanata con el calendario */
.ui-datepicker {
  width: 100%;
  top: 44px !important;
}

/* FIn estilos input fecha PrimeNG */

@page {
  margin: 0.5cm;
  size: auto;
  /*size: portrait;*/
  /*page-orientation: upright;*/
}

/* Clases para estilos personalizados */
.white-font {
  color: white !important;
}

.margin-top-35{
  margin-top: 35px;
}

.padding-0 {
  padding: 0 !important;
}

.no-shadow {
  box-shadow: 0 0 0 0 rgba(154,161,171,.15);
}

a.gray-hover:hover {
  color: #878c90!important;
}

.report-border {
  border: solid 1px #d0d0d0;
}

.display-none {
  display: none;
}

@media print {
  body{
    color: black;
  }

  body, tr {
    -webkit-print-color-adjust: exact !important;
  }

  body, tr, td {
    -webkit-print-color-adjust: exact !important;
  }

  .p-print-0 {
    padding: 0 !important;
  }

  .m-print-0{
      margin: 0 !important;
  }

  .hidden-print {
    display: none !important;
  }

  .report-border {
    border: solid 0px #d0d0d0;
  }

  .mat-tab-header, .am-scroll-top{
    display: none !important;
  }

  .print-margin {
    margin: 0 !important;
  }

  h1.saltoDePagina{
    display:block;
    page-break-before:always;
  }

  .col-print-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }

  /*app-root > * { display: none; }
  app-root app-print-layout { display: block; }*/
  .isPrinting > * { display: none; }
  .isPrinting app-print-layout, .isPrinting app-print-clases, .isPrinting app-print-checks-format, .isPrinting app-print-divisions, .isPrinting app-print-class-sheets { display: block; }
}

.input-error{
  border-color: red;
}

body {
  padding: 0 !important;
  background-color: transparent;
}

/*Hacer input de datables mas chico en entries*/
.entries .dataTables_filter input{
  max-width: 90px;
}

/* Mat autocomplete en modal */
.cdk-overlay-container{
  z-index: 1050;
}

.table td, .table th{
  vertical-align: middle;
}

.dataTable td{
  padding: 0.5rem 0.95rem !important;
}

div.dataTables_wrapper div.dataTables_filter{
  float: right;
}

.bootstrap-datetimepicker-widget.dropdown-menu{
  width: fit-content;
}

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

app-riders-duplicated #personas tbody tr{
  cursor: pointer;
}

app-riders-duplicated #personas tbody tr{
  cursor: pointer;
}

.mat-autocomplete-panel{
  background-color: #fafbfe!important;
}

.dt-buttons button{
  margin-right: 1.5rem !important;
}

.form-check-input{
  position: unset;
  margin-top: 0;
  margin-left: 0;
}

cdk-describedby-message-container{
  background-color: red;
}

.list-group-horizontal {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
}

.list-group-horizontal>.list-group-item:last-child:not(:first-child) {
  border-top-right-radius: 0.25rem;
  border-bottom-left-radius: 0;
}

.list-group-horizontal>.list-group-item:first-child:not(:last-child) {
  border-bottom-left-radius: 0.25rem;
  border-top-right-radius: 0;
}


.list-group-horizontal>.list-group-item+.list-group-item {
  border-top-width: 1px;
  border-left-width: 0;
}

@media print {
  .col-print-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; } }

@media print {
  .col-print-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; } }

@media print {
  .col-print-3 {
    flex: 0 0 25%;
    max-width: 25%; } }

@media print {
  .col-print-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; } }

@media print {
  .col-print-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; } }

@media print {
  .col-print-6 {
    flex: 0 0 50%;
    max-width: 50%; } }

@media print {
  .col-print-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; } }

@media print {
  .col-print-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; } }

@media print {
  .col-print-9 {
    flex: 0 0 75%;
    max-width: 75%; } }

@media print {
  .col-print-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; } }

@media print {
  .col-print-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; } }

@media print {
  .col-print-12 {
    flex: 0 0 100%;
    max-width: 100%; } }

    /**
    *  Placeholder card
    */

    .placeholder {
      display: inline-block;
      min-height: 1em;
      vertical-align: middle;
      cursor: wait;
      background-color: currentcolor;
      opacity: .5
  }

  .placeholder.btn::before {
      display: inline-block;
      content: ""
  }

  .placeholder-xs {
      min-height: .6em
  }

  .placeholder-sm {
      min-height: .8em
  }

  .placeholder-lg {
      min-height: 1.2em
  }

  .placeholder-glow .placeholder {
      -webkit-animation: placeholder-glow 2s ease-in-out infinite;
      animation: placeholder-glow 2s ease-in-out infinite
  }

  @-webkit-keyframes placeholder-glow {
      50% {
          opacity: .2
      }
  }

  @keyframes placeholder-glow {
      50% {
          opacity: .2
      }
  }

  .placeholder-wave {
      -webkit-mask-image: linear-gradient(130deg,#000 55%,rgba(0,0,0,.8) 75%,#000 95%);
      mask-image: linear-gradient(130deg,#000 55%,rgba(0,0,0,.8) 75%,#000 95%);
      -webkit-mask-size: 200% 100%;
      mask-size: 200% 100%;
      -webkit-animation: placeholder-wave 2s linear infinite;
      animation: placeholder-wave 2s linear infinite
  }

  @-webkit-keyframes placeholder-wave {
      100% {
          -webkit-mask-position: -200% 0;
          mask-position: -200% 0
      }
  }

  @keyframes placeholder-wave {
      100% {
          -webkit-mask-position: -200% 0;
          mask-position: -200% 0
      }
  }

  .usef-logo{
    max-width: 30px;
    max-height: 30px;
    margin-top: -3px;
    margin-right: 8px;
  }

  .fei-logo{
    margin-top: -7px;
    margin-right: 8px;
    height: 30px;
    width: 30px;
  }

  .ec-logo{
    max-width: 30px;
    max-height: 30px;
    margin-top: -3px;
    margin-right: 8px;
  }




  .tt-menu{
    background-color: #fafbfe;
    width: 100% !important;
  }

  .tt-suggestion{
    background-color: #f1f1f1;
    padding: 10px;
    cursor: pointer;
  }

  .signature, .signature svg{
    width: 100%;
    min-height: 150px;
  }


  .btn-fei{
    background-color: #4a0d53;
    color: white
  }
